import omitBy from 'lodash.omitby';
import { createDiscoverPageDescription } from '@/helpers/seo/discover';
import CONFIG from '@/config/global';

const { appName, logoUrl } = CONFIG;
const CONTEXT_STRING = 'http://schema.googleapis.com/';
const appHost = 'https://www.keakie.com';
const appDescription = createDiscoverPageDescription();

const formatJSONLDObject = (object) => omitBy(object, (v) => !v);

export const createSDOrganisation = () => formatJSONLDObject({
  '@context': CONTEXT_STRING,
  '@type': 'Organization',
  name: appName,
  url: appHost,
  logo: logoUrl
});

export const createSDSoftwareApplication = ({
  operatingSystem,
  url
} = {}) => formatJSONLDObject({
  '@context': CONTEXT_STRING,
  '@type': 'SoftwareApplication',
  name: appName,
  description: appDescription,
  operatingSystem,
  applicationCategory: 'MUSIC_AND_AUDIO',
  url,
  contentRating: 'Teen',
  image: logoUrl,
  author: {
    '@type': 'Organization',
    name: appName,
    url: appHost
  }
});

export const createSDMusicGroup = ({
  id,
  url,
  keakieUrlPath,
  name,
  description,
  excludeContext = false
} = {}) => {
  const urlFormatted = url || `${ appHost }${ keakieUrlPath }`;

  return formatJSONLDObject({
    '@context': excludeContext ? null : CONTEXT_STRING,
    '@type': 'MusicGroup',
    '@id': id || urlFormatted,
    url: urlFormatted,
    name,
    description: description || `Listen to ${ name } on ${ appName }`
  });
};

export const createSDMusicAlbum = ({
  id,
  url,
  keakieUrlPath,
  datePublished,
  name,
  description,
  imageUrl,
  byArtist,
  excludeContext = false
} = {}) => {
  const urlFormatted = url || `${ appHost }${ keakieUrlPath }`;

  return formatJSONLDObject({
    '@context': excludeContext ? null : CONTEXT_STRING,
    '@type': 'MusicAlbum',
    '@id': id || urlFormatted,
    url: urlFormatted,
    name,
    description: description || `Listen to ${ name } on ${ appName }`,
    datePublished,
    image: imageUrl,
    byArtist
  });
};

export const createSDMusicRecording = ({
  id,
  url,
  keakieUrlPath,
  datePublished,
  name,
  description,
  imageUrl,
  inAlbum,
  byArtist
} = {}) => {
  const urlFormatted = url || `${ appHost }${ keakieUrlPath }`;

  return formatJSONLDObject({
    '@context': 'http://schema.googleapis.com/',
    '@type': 'MusicRecording',
    '@id': id || urlFormatted,
    url: urlFormatted,
    name,
    description: description || `Listen to ${ name } on ${ appName }`,
    datePublished,
    image: imageUrl,
    inAlbum,
    byArtist
  });
};