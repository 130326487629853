import { useEffect } from 'react';
import useIntercom from '@/hooks/intercom/useIntercom';

const useSendIntercomEvent = ({
  createEvent,
  waitForUserId = false,
  shouldSend = true
}) => {
  const {
    userIdReady,
    sendIntercomEvent,
    shouldSendEvents
  } = useIntercom();

  useEffect(() => {
    if (shouldSendEvents && shouldSend && (waitForUserId ? userIdReady : true)) {
      sendIntercomEvent(createEvent());
    }
  }, [
    userIdReady,
    waitForUserId,
    shouldSend,
    createEvent,
    sendIntercomEvent,
    shouldSendEvents
  ]);
};

export default useSendIntercomEvent;