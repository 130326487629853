import { useCallback, useState } from 'react';
import sendRowImpressionEvent from '@/helpers/api/events/sendRowImpressionEvent';
import useCallApi from '@/hooks/api/useCallApi';
import useIntersectionObserver from '@/hooks/browser/useIntersectionObserver';

const useRowImpressionEvent = ({
  recommendationId = '',
  recommendationType = '',
  shouldCall = false,
  onImpressionSuccess = undefined
}) => {
  const [impressionEventSent, setImpressionEventSent] = useState(false);

  const { ref: relatedRowImpressionRef, inView: impressionInView } = useIntersectionObserver({
    rootMargin: '0% 0% 0% 0%',
    threshold: 0.85
  });

  const handleSendImpression = useCallback(() => sendRowImpressionEvent({
    recommendationId,
    recommendationType
  }), [recommendationId, recommendationType]);

  const handleSuccess = useCallback(() => {
    setImpressionEventSent(true);

    if (onImpressionSuccess) {
      onImpressionSuccess();
    }
  }, [onImpressionSuccess]);

  const handleFailure = useCallback(() => {
    // Stop repeat calls - temporary?
    setImpressionEventSent(true);
  }, []);

  useCallApi({
    call: handleSendImpression,
    shouldCall: !impressionEventSent && shouldCall && impressionInView,
    onSuccess: handleSuccess,
    onFailure: handleFailure
  });

  return { relatedRowImpressionRef };
};

export default useRowImpressionEvent;