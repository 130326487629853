import {
  iconCommonProps, iconDefaultProps, iconPropTypes
} from '../propTypes';

const Caret = ({ className, color }) => (
  <svg
    className={ className }
    { ...iconCommonProps }
    viewBox='0 0 10.45 18.91'
  >
    <title>Caret Icon</title>
    <g>
      <path
        d='M1,18.91A1,1,0,0,1,.29,17.2L8,9.45.29,1.71A1,1,0,0,1,1.71.29l8.45,8.46a1,1,0,0,1,.29.7,1,1,0,0,1-.29.71L1.71,18.61A1,1,0,0,1,1,18.91Z'
        fill={ color }
      />
    </g>
  </svg>
);

Caret.propTypes = { ...iconPropTypes };

Caret.defaultProps = { ...iconDefaultProps };

export default Caret;