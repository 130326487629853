import PropTypes from 'prop-types';
import React, {
  memo, useEffect, useState
} from 'react';
import {
  LARGE, MEDIUM, SMALL, TILESMALL, XLARGE, XSMALL
} from '@/config/assets';
import { truthyOrZero } from '@/helpers/math';
import useBrowserAssetWidthRange from '@/hooks/layout/useBrowserAssetWidthRange';
import useInitialRender from '@/hooks/browser/useInitialRender';

const ImageLoaderAssetSizeContainer = ({
  children,
  assetSize,
  forceImageRefresh
}) => {
  const [currentBreakpointWidth, setCurrentBreakpoint] = useState();
  const [currentWidthKey, setCurrentWidthKey] = useState();

  const { isFirstRender } = useInitialRender();

  const {
    assetSizeForScreenWidth, // Track the browser asset size (XLARGE, LARGE etc...)
    assetWidthRange // Track the screen width range
  } = useBrowserAssetWidthRange();

  const breakpointWidth = assetWidthRange.min;

  useEffect(() => {
    // Unless forceImageRefresh is true, only set the new size key if the screen width has become larger
    if (
      !assetSize
      && (
        (!truthyOrZero(currentBreakpointWidth) && assetSizeForScreenWidth)
        || forceImageRefresh
        || (breakpointWidth > currentBreakpointWidth)
      )
    ) {
      setCurrentBreakpoint(breakpointWidth);
      setCurrentWidthKey(assetSizeForScreenWidth);
    }
  }, [assetSize, assetSizeForScreenWidth, breakpointWidth, forceImageRefresh, currentBreakpointWidth]);

  const screenWidthKeyProp = assetSize || currentWidthKey;

  return React.cloneElement(children, {
    assetSize: screenWidthKeyProp,
    key: screenWidthKeyProp,
    isInitialRender: isFirstRender
  });
};

ImageLoaderAssetSizeContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  assetSize: PropTypes.oneOf([XLARGE, LARGE, MEDIUM, SMALL, XSMALL, TILESMALL]),
  forceImageRefresh: PropTypes.bool
};

ImageLoaderAssetSizeContainer.defaultProps = {
  assetSize: undefined,
  forceImageRefresh: false
};

export default memo(ImageLoaderAssetSizeContainer);