import { useEffect, useState } from 'react';

// This hook is used for handling API calls.
// shouldCall triggers the API call.
// It handles success/loading/error states internally
// as the API call happens, and returns them
const useCallApi = ({
  call,
  shouldCall = true,
  formatResponse = _ => _,
  onSuccess,
  onFailure = _ => _
}) => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, toggleError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Pass this down to whatever is using the hook so it can retry the call
  const retry = () => {
    setSuccess(false);
    setLoading(true);
    setResponse(null);
    toggleError(null);
  };

  const handleResponse = (resObj) => {
    const formatted = formatResponse(resObj);
    onSuccess(formatted);
    setLoading(false);
    setSuccess(true);
    setResponse(formatted);
  };

  const handleFailure = (err) => {
    onFailure(err);
    setSuccess(false);
    setLoading(false);
    toggleError(true);
  };

  useEffect(() => {
    if (shouldCall && !loading && !success) {
      setLoading(true);
      call().then((res) => {
        handleResponse(res);
      }).catch((err) => {
        handleFailure(err);
      });
    }
    // eslint-disable-next-line
  }, [shouldCall, success]);

  return {
    retry,
    response,
    loading,
    error,
    success
  };
};

export default useCallApi;