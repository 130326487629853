// PAGE VIEWS
export const IC_EVT_VIEWED_PAGE_DISCOVER = 'page_viewed_discover';
export const IC_EVT_VIEWED_PAGE_LIBRARY = 'page_viewed_library';
export const IC_EVT_VIEWED_PAGE_SHOW = 'page_viewed_show';
export const IC_EVT_VIEWED_PAGE_GENRE = 'page_viewed_genre';

// AUDIO
export const IC_EVT_STREAMED_EPISODE_START = 'streamed_episode_start';
export const IC_EVT_STREAMED_EPISODE = 'streamed_episode';
export const IC_EVT_STREAMED_GENRE_START = 'streamed_genre_start';
export const IC_EVT_STREAMED_GENRE = 'streamed_genre';
export const IC_EVT_AUDIO_PLAYBACK_STARTED = 'audio_playback_started';

// SEARCH
export const IC_EVT_PERFORMED_SEARCH = 'searched';