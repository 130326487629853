import CONFIG from '@/config/global';
import { callApi } from '@/helpers/api';

const {
  api: {
    HOST,
    EVENTS_ROW_IMPRESSION
    // EVENTS_KEAKIE
  }
} = CONFIG;

// Send an event to notify the API that a row has been viewed.
// This happens for any channel row displayed throughout the app.
const sendRowImpressionEventLegacy = ({
  recommendationId,
  recommendationType
}) => {
  return callApi({
    url: HOST(EVENTS_ROW_IMPRESSION) + EVENTS_ROW_IMPRESSION,
    method: 'POST',
    body: {
      rt: recommendationType,
      rid: recommendationId
    }
  });
};

// const sendRowImpressionEvent = ({
//   recommendationId,
//   recommendationType
// }) => {
//   return callApi({
//     url: HOST(EVENTS_KEAKIE) + EVENTS_KEAKIE,
//     method: 'POST',
//     body: {
//       context: {
//         from: recommendationType,
//         from_id: recommendationId
//       },
//       event_name: 'row_impression',
//       event_timestamp: new Date().toISOString()
//       // performance: { is_successful: true },
//       // resource: {
//       //   id: resourceId,
//       //   type: resourceType
//       // }
//     }
//   });
// };

export default sendRowImpressionEventLegacy;