import { memo } from 'react';
import PropTypes from 'prop-types';
import ShowPreviewRow from '@/components/component-library/ShowPreviewRow';
import useIntersectionObserver from '@/hooks/browser/useIntersectionObserver';
import { isTouchDevice } from '@/helpers/browser';

const ConnectedShowPreviewRow = ({
  intersectionRoot,
  onInView,
  isLoading,
  ...props
}) => {
  const useTouchControls = isTouchDevice();

  // This tracks whether the row has intersected with the provided intesection root at least once.
  const { ref, hasEnteredViewOnce } = useIntersectionObserver({
    root: intersectionRoot,
    rootMargin: '66% 0% 66% 0%',
    onEnterView: onInView,
    disabled: !isLoading
  });
    
  return (
    <ShowPreviewRow
      { ...props }
      isInView={ hasEnteredViewOnce }
      isLoading={ isLoading }
      rowRef={ ref }
      useTouchControls={ useTouchControls }
    />
  );
};

ConnectedShowPreviewRow.propTypes = {
  intersectionRoot: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  isLoading: PropTypes.bool,
  onInView: PropTypes.func
};

ConnectedShowPreviewRow.defaultProps = {
  intersectionRoot: null,
  isLoading: false,
  onInView: undefined
};

export default memo(ConnectedShowPreviewRow);