import PropTypes from 'prop-types';
import Gradient, {
  GRADIENT_POSITION_BOTTOM, GRADIENT_POSITION_LEFT, GRADIENT_WEIGHT_KEY_LARGE
} from '../../Gradient';
import ImageLoaderAssetSizeContainer from '../../ImageLoaderAssetSizeContainer';
import styles from './styles.module.scss';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';
import HeroImage from '@/components/component-library/Image/KeakieImageHero';
import { createImageAltTextForShow } from '@/helpers/seo/shows';
import { classes } from '@/helpers/styling';

const ShowHeroDesktop = ({
  show,
  contentBottomAligned,
  isLoading,
  className,
  style,
  enableLeftGradient,
  children
}) => {
  const { assets } = show || {};

  return (
    <div className={ classes(styles.showTopBanner, className) } style={ style }>
      { !!enableLeftGradient && (
        <Gradient
          gradientBreadth='100%'
          position={ GRADIENT_POSITION_LEFT }
          weight={ GRADIENT_WEIGHT_KEY_LARGE }
        />
      ) }
      <div className={ styles.heroSectionWrapper }>
        <div className={ classes(styles.heroSectionInfo, { [styles.bottomAligned]: contentBottomAligned }) }>
          <LayoutWrapperHorizontal className={ styles.internalWrapper } fillHeight isRow>
            { children }
          </LayoutWrapperHorizontal>
        </div>
      </div>
      <div className={ styles.showHeroImageWrapper }>
        <ImageLoaderAssetSizeContainer>
          <HeroImage
            alt={ createImageAltTextForShow({ show }) }
            assetsObj={ assets }
            className={ styles.showTopBannerImage }
            loading={ isLoading }
            priority
          />
        </ImageLoaderAssetSizeContainer>
        { !!enableLeftGradient && (
          <Gradient
            className={ styles.showHeroImageLeftGradientWrapper }
            gradientBreadth='50%'
            position={ GRADIENT_POSITION_LEFT }
            weight={ GRADIENT_WEIGHT_KEY_LARGE }
          />
        ) }
        <Gradient
          className={ styles.showHeroImageBottomGradientWrapper }
          directionReverse
          gradientBreadth='75%'
          position={ GRADIENT_POSITION_BOTTOM }
          weight={ GRADIENT_WEIGHT_KEY_LARGE }
        />
      </div>
    </div>
  );
};

ShowHeroDesktop.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  show: PropTypes.object,
  contentBottomAligned: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
  enableLeftGradient: PropTypes.bool
};

ShowHeroDesktop.defaultProps = {
  children: null,
  show: {},
  contentBottomAligned: false,
  className: '',
  style: {},
  isLoading: false,
  enableLeftGradient: false
};

export default ShowHeroDesktop;