import { useEffect } from 'react';
import useGA from '@/hooks/ga/useGA';

const useSendGAEvent = ({
  createEvent,
  waitForUserId = true,
  shouldSend = true
}) => {
  const {
    sendGAEvent, userIdReady, shouldSendEvents
  } = useGA();

  useEffect(() => {
    if (shouldSend && shouldSendEvents && (!waitForUserId || userIdReady)) {
      sendGAEvent(createEvent());
    }
  }, [
    userIdReady,
    waitForUserId,
    shouldSend,
    createEvent,
    sendGAEvent,
    shouldSendEvents
  ]);
};

export default useSendGAEvent;