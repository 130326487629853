import {
  GA_ACT_VIEWED_PAGE_DISCOVER,
  GA_ACT_VIEWED_PAGE_GENRE,
  GA_ACT_VIEWED_PAGE_LIBRARY,
  GA_ACT_VIEWED_PAGE_SHOW
} from '@/config/events-ga/actions';
import { GA_CAT_PAGE_VIEW } from '@/config/events-ga/categories';
import {
  GA_DIM_GENRE_ID,
  GA_DIM_GENRE_PARENT_ID,
  GA_DIM_GENRE_PARENT_TITLE,
  GA_DIM_GENRE_TITLE,
  GA_DIM_IS_NEW,
  GA_DIM_SHOW_ID,
  GA_DIM_SHOW_TITLE
} from '@/config/events-ga/dimensions';

export const GApageViewDiscover = () => ({
  eventCategory: GA_CAT_PAGE_VIEW,
  eventAction: GA_ACT_VIEWED_PAGE_DISCOVER
});

export const GApageViewLibrary = () => ({
  eventCategory: GA_CAT_PAGE_VIEW,
  eventAction: GA_ACT_VIEWED_PAGE_LIBRARY
});

export const GApageViewShow = ({ showId, showTitle }) => ({
  eventCategory: GA_CAT_PAGE_VIEW,
  eventAction: GA_ACT_VIEWED_PAGE_SHOW,
  dimensions: {
    [GA_DIM_SHOW_ID]: showId,
    [GA_DIM_SHOW_TITLE]: showTitle
  }
});

export const GApageViewGenre = ({
  genreId,
  genreTitle,
  parentGenreId,
  parentGenreTitle,
  isNew
}) => ({
  eventCategory: GA_CAT_PAGE_VIEW,
  eventAction: GA_ACT_VIEWED_PAGE_GENRE,
  dimensions: {
    [GA_DIM_GENRE_ID]: genreId,
    [GA_DIM_GENRE_TITLE]: genreTitle,
    [GA_DIM_GENRE_PARENT_ID]: parentGenreId || undefined,
    [GA_DIM_GENRE_PARENT_TITLE]: parentGenreTitle || undefined,
    [GA_DIM_IS_NEW]: isNew || undefined
  }
});