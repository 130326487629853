import PropTypes from 'prop-types';
import Badge from '../Badge';
import CaretIcon from '@/components/component-library/Icon/types/Caret';
import Link from '@/components/component-library/Link';
import Text, { FONT_SIZE_070, FONT_SIZE_100 } from '@/components/component-library/Text';
import { classes } from '@/helpers/styling';

// Row header which includes the title and the description
const RowHeader = ({
  title,
  description,
  isLoading,
  error,
  hideDescription,
  enableLeftArrow,
  enableRightArrow,
  useTouchControls,
  onClickViewMore,
  titleHref,
  onClickArrowLeft,
  onClickArrowRight,
  usePillForTitle
}) => {
  return (
    <div className='show-row-header'>
      <div className='show-row-title-wrapper'>
        { usePillForTitle ? (
          <Badge
            className='genre-header-badge'
            href={ titleHref }
            largeSize
            loading={ isLoading }
            title={ title }
          />
        ) : (
          <Link
            className={ classes('show-row-title', {
              '--is-clickable': onClickViewMore,
              '--is-loading': isLoading
            }) }
            href={ titleHref }
          >
            <Text
              bold
              loading={ isLoading }
              onClick={ onClickViewMore }
              size={ FONT_SIZE_100 } useHeaderFont
            >
              { !isLoading ? title : '' }
            </Text>
          </Link>
        ) }
        { !hideDescription && (
          <Text
            bold
            className={ classes('show-row-description', { '--is-loading': isLoading }) }
            loading={ isLoading }
            size={ FONT_SIZE_070 }
            useHeaderFont
          >
            { description }
          </Text>
        ) }
      </div>
      { !useTouchControls && !error && (
        <div className={ classes('show-row-controls', { '--is-active': (enableLeftArrow || enableRightArrow) }) }>
          <button
            aria-label='row-slide-left'
            className={ classes('show-row-caret-control --faces-left', { '--is-active': enableLeftArrow }) }
            disabled={ !enableLeftArrow }
            onClick={ enableLeftArrow ? onClickArrowLeft : undefined }
          >
            <CaretIcon />
          </button>
          <button
            aria-label='row-slide-right'
            className={ classes('show-row-caret-control', { '--is-active': enableRightArrow }) }
            disabled={ !enableRightArrow }
            onClick={ enableRightArrow ? onClickArrowRight : undefined }
          >
            <CaretIcon />
          </button>
        </div>
      ) }
    </div>
  );
};

RowHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  hideDescription: PropTypes.bool,
  enableLeftArrow: PropTypes.bool,
  enableRightArrow: PropTypes.bool,
  useTouchControls: PropTypes.bool,
  onClickViewMore: PropTypes.func,
  onClickArrowLeft: PropTypes.func,
  onClickArrowRight: PropTypes.func,
  titleHref: PropTypes.string,
  usePillForTitle: PropTypes.bool
};

RowHeader.defaultProps = {
  title: '',
  description: '',
  isLoading: false,
  error: false,
  hideDescription: false,
  enableLeftArrow: false,
  enableRightArrow: false,
  useTouchControls: false,
  onClickViewMore: undefined,
  onClickArrowLeft: undefined,
  onClickArrowRight: undefined,
  titleHref: '',
  usePillForTitle: false
};

export default RowHeader;