import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import BadgeList from '@/components/component-library/BadgeList';
import pageKeys from '@/helpers/routing/constants';

const GenreBadges = ({
  genres,
  onClickGenre,
  enableLinks,
  loading,
  loadingCount,
  className,
  ...props
}) => {
  const genresWithHrefs = useMemo(() => (genres || []).map((g) => ({
    ...g,
    href: enableLinks ? pageKeys.genre(g.slug) : undefined
  })), [genres, enableLinks]);

  return (
    <BadgeList
      className={ className }
      enableLinks={ enableLinks }
      items={ genresWithHrefs }
      loading={ loading }
      loadingCount={ loadingCount }
      onClickBadge={ onClickGenre || undefined }
      { ...props }
    />
  );
};

GenreBadges.propTypes = {
  genres: PropTypes.arrayOf(PropTypes.object),
  onClickGenre: PropTypes.func,
  enableLinks: PropTypes.bool,
  loading: PropTypes.bool,
  loadingCount: PropTypes.number,
  className: PropTypes.string
};

GenreBadges.defaultProps = {
  genres: [],
  onClickGenre: undefined,
  enableLinks: true,
  loading: false,
  loadingCount: 2,
  className: ''
};

export default memo(GenreBadges);