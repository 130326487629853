import {
  CHILD_GENRES,
  DEFAULT_GENRES,
  PREFERRED_GENRES,
  TIMED_GENRES
} from '@/config/events-keakie';

// Check whether a recommendation type is one of the 4 genre types.
export const isGenreRecommendation = (recommendationType) => {
  const rt = recommendationType;

  return !!recommendationType
    && (
      (rt === DEFAULT_GENRES)
      || (rt === CHILD_GENRES)
      || (rt === PREFERRED_GENRES)
      || (rt === TIMED_GENRES)
    );
};