import { isBrowser } from '@/helpers/browser';

export const SCROLL_DIRECTION_LEFT = 'left';
export const SCROLL_DIRECTION_RIGHT = 'right';

// Get the computed gridGap and paddingLeft values as numbers from the row element
// This is used to calculate scroll positions in rows
export const calculateRowStyles = ({ rowElement }) => {
  if (!isBrowser() || !rowElement) {
    return {
      gridGap: 0,
      paddingLeft: 0
    };
  }

  const { offsetWidth = 0 } = rowElement || {};

  const gridGap = getComputedStyle(rowElement).gridColumnGap;
  const gridGapIsPercentage = gridGap.includes('%');
  const gridGapNumber = Number(gridGap.replace(gridGapIsPercentage ? '%' : 'px', '')) * (gridGapIsPercentage ? (0.01 * offsetWidth) : 1);

  const { paddingLeft } = getComputedStyle(rowElement);
  const paddingLeftIsPercentage = paddingLeft.includes('%');
  const paddingLeftNumber = Number(paddingLeft.replace(paddingLeftIsPercentage ? '%' : 'px', '')) * (paddingLeftIsPercentage ? (0.01 * offsetWidth) : 1);

  return {
    gridGap: gridGapNumber,
    paddingLeft: paddingLeftNumber
  };
};

// Calculate scroll position information for a show row. This is for the arrow buttons which scroll the row on click
// Returns:
// scrollIsAtStart - Boolean flag as to whether the scroll position is at the start of the row
// scrollIsAtEnd - Boolean flag as to whether the scroll position is at the end of the row
// newScrollLeft - Number as to the what the px scrollLeft value of the row should be after scrolling in the provided direction
export const calculateRowScrollPositions = ({
  direction = SCROLL_DIRECTION_RIGHT, // 'right' or 'left' - the intended direction of scroll
  rowElement // The row element to be scrolled
}) => {
  if (!isBrowser() || !rowElement) {
    return {
      scrollIsAtStart: false,
      scrollIsAtEnd: false,
      newScrollLeft: 0
    };
  }

  const rowsShouldScrollRight = (direction === SCROLL_DIRECTION_RIGHT);

  // Get the current widths and scroll positions for the row element
  const {
    scrollLeft = 0, // The current scroll position within the row
    offsetWidth = 0, // The width of the row on screen
    scrollWidth = 0 // The full scrolling width of the row
  } = rowElement || {};

  const { paddingLeft, gridGap } = calculateRowStyles({ rowElement });

  // The width to scroll each time an arrow is clicked is the offsetWidth, minus padding, plus an adjustment of the grid gap
  const scrollingWidth = offsetWidth - (paddingLeft * 2) + gridGap;
  // Is the scroll currently at the start?
  const scrollIsAtStart = (scrollLeft === 0);
  // Is the scroll currently at the end?
  const scrollIsAtEnd = (scrollWidth - (scrollLeft + offsetWidth)) < 15;

  // Calculate scrollLeft position to scroll to
  const newScrollLeft = rowsShouldScrollRight
    ? scrollLeft + scrollingWidth
    : scrollLeft - scrollingWidth;

  return {
    scrollIsAtStart,
    scrollIsAtEnd,
    newScrollLeft
  };
};