import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import {
  SCROLL_DIRECTION_LEFT, SCROLL_DIRECTION_RIGHT, calculateRowScrollPositions, calculateRowStyles
} from './utils';
import RowHeader from './RowHeader';
import { size, map } from '@/helpers/lodash';
import useInitialRender from '@/hooks/browser/useInitialRender';
import useRowImpressionEvent from '@/hooks/events/useRowImpressionEvent';
import useWindowSize from '@/hooks/browser/useWindowSize';
import ConnectedShowPreviewSlate from '@/components/app/ConnectedShowPreviewSlate';
import LayoutWrapperHorizontal from '@/components/component-library/LayoutWrapperHorizontal';
import { classes } from '@/helpers/styling';
import { isDesktopSafari, satisfiesBrowser } from '@/helpers/browser';
import { isGenreRecommendation } from '@/helpers/pages/discover';
import pageKeys from '@/helpers/routing/constants';
import { createImageAltTextForShow } from '@/helpers/seo/shows';

const browserIsDesktopSafari = isDesktopSafari();
// Since safari 15.4, the spacer element is no longer needed for scroll snap to work
const safariScrollRequiresSpacer = satisfiesBrowser({ safari: '<15.4' });

const ShowPreviewRow = ({
  // Data
  id,
  title,
  description,
  channelPreviews,
  recommendationId,
  recommendationType,
  recommendationSlug,
  rowRef,
  // Loading/error state
  isLoading,
  isLoadingShows,
  error,
  // Options
  disableHeader,
  disableNativeScroll,
  isExpandable, // If true, the row title can be clicked
  hideDescription,
  disableImpressionEvent, // Stop the impression event from being fired
  useTouchControls,
  isInView,
  disable,
  hideSlateOverlayInfo,
  className,
  // Handlers
  onClickChannel,
  onImpressionSuccess // Function to call after the impression event is sent to the API
}) => {
  const { isFirstRender } = useInitialRender();
  const { push: routerPush } = useRouter();

  const shouldHideDescription = hideDescription || (!isLoading && !description);
  const enableTouchControls = !isFirstRender && useTouchControls;
  const recommendedSlug = isExpandable ? recommendationSlug : null;
  const channelsFetched = !isLoadingShows;
  const loadingSlates = !isInView || isLoadingShows || error;
  // If there are no channel previews loaded yet then display 8 empty channels as a placeholder
  const previews = (isInView && channelsFetched)
    ? channelPreviews
    : [...Array(8)].map(() => ({}));

  // ------ IMPRESSION EVENTS ---
  // ----------------------------

  // Send a row impression event if the channels are loaded and the row has been viewed by the user
  const { relatedRowImpressionRef } = useRowImpressionEvent({
    shouldCall: !!id && !disableImpressionEvent && channelsFetched && !isLoading,
    recommendationId,
    recommendationType,
    onImpressionSuccess
  });

  // ------ SCROLL STATE --------
  // ----------------------------
  const listRef = useRef();

  // State for row sliding buttons on desktop
  const [canSlideLeft, setCanSlideLeft] = useState(false);
  const [canSlideRight, setCanSlideRight] = useState(true);
  const enableLeftArrow = !isLoading && isInView && channelsFetched && canSlideLeft;
  const enableRightArrow = !isLoading && isInView && channelsFetched && canSlideRight;
  const { paddingLeft, gridGap } = useMemo(() => calculateRowStyles({ rowElement: listRef?.current }), [listRef]);

  // Update whether the row can be scrolled based on it's current scroll position
  const updateScrollState = useCallback(() => {
    const { scrollIsAtStart, scrollIsAtEnd } = calculateRowScrollPositions({ rowElement: listRef?.current });

    setCanSlideLeft(!scrollIsAtStart);
    setCanSlideRight(!scrollIsAtEnd);
  }, []);

  // Attach scroll listeners
  useEffect(() => {
    const list = listRef?.current;
  
    list.addEventListener('scroll', updateScrollState);
  
    return () => {
      list.removeEventListener('scroll', updateScrollState);
    };
  }, [updateScrollState]);

  // When the list ref is attached, update scroll state
  useEffect(() => {
    if (listRef?.current) {
      updateScrollState();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRef]);

  // Track window resize as this will cause the css scroll snap to misalign
  const { width } = useWindowSize({ debounceTime: 250 });

  // If the window width changes then we need to resnap the row.
  // Programatically scroll the list to it's current position
  // so that the css scroll snap will resnap
  useEffect(() => {
    if (!isLoadingShows) {
      listRef?.current?.scrollTo({ left: listRef?.current?.scrollLeft });
      updateScrollState();
    }
  }, [updateScrollState, width, isLoadingShows]);
  
  // ------ HANDLERS ------------
  // ----------------------------

  // Handle clicking of the left or right arrow
  const handleClickArrow = useCallback((direction = SCROLL_DIRECTION_RIGHT) => {
    const rowElement = listRef?.current;

    if (rowElement) {
      // Calculate the new scroll left after srolling the width of the row
      const { newScrollLeft } = calculateRowScrollPositions({
        direction,
        rowElement
      });

      rowElement.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth'
      });
    }
  }, [listRef]);

  const canClickToVisitGenre = (!!recommendedSlug && isGenreRecommendation(recommendationType));
  const titleClickIsEnabled = isExpandable && canClickToVisitGenre;

  // Handle specifically scrolling left or right
  const handleClickArrowLeft = useCallback(() => handleClickArrow(SCROLL_DIRECTION_LEFT), [handleClickArrow]);
  const handleClickArrowRight = useCallback(() => handleClickArrow(SCROLL_DIRECTION_RIGHT), [handleClickArrow]);

  // Handle clicking the row title to view more. This is only enabled if the recommendation type is genre
  const handleLinkToGenre = useCallback(() => routerPush(pageKeys.genre(recommendedSlug)), [recommendedSlug, routerPush]);
  const handleClickViewMore = useCallback(() => {
    if (canClickToVisitGenre) {
      handleLinkToGenre();
    }
  },
  [handleLinkToGenre, canClickToVisitGenre]);

  if (!size(previews) && !isLoading) {
    return null;
  }

  return (
    <div
      className={ classes(className, 'show-row --is-transitioned', { '--is-disabled': isLoading || disable }) }
      data-testid='show-row'
      ref={ rowRef }
    >
      <div
        className='show-row-impression-wrapper'
        ref={ relatedRowImpressionRef }
      >
        { !disableHeader && (
          <LayoutWrapperHorizontal isRow>
            <RowHeader
              description={ description }
              enableLeftArrow={ enableLeftArrow }
              enableRightArrow={ enableRightArrow }
              error={ error }
              hideDescription={ shouldHideDescription }
              isLoading={ isLoading }
              onClickArrowLeft={ handleClickArrowLeft }
              onClickArrowRight={ handleClickArrowRight }
              onClickViewMore={ titleClickIsEnabled ? handleClickViewMore : undefined }
              title={ title }
              titleHref={ titleClickIsEnabled
                ? pageKeys.genre(recommendedSlug)
                : undefined }
              usePillForTitle={ canClickToVisitGenre }
              useTouchControls={ enableTouchControls }
            />
          </LayoutWrapperHorizontal>
        ) }
        <ul
          className={ classes(
            'show-row-slate-list slate-snap-align',
            {
              '--disable-scroll': disableNativeScroll || !enableTouchControls,
              '--is-mobile': enableTouchControls && !disableNativeScroll,
              '--disable-scroll-snap': enableTouchControls
            }
          ) }
          ref={ listRef }
        >
          {
            map(previews, (channel = {}, index) => (
              <ConnectedShowPreviewSlate
                altText={ createImageAltTextForShow({ show: channel }) }
                assets={ channel.assets }
                intersectionRoot={ listRef?.current }
                key={ channel.id || index }
                loading={ loadingSlates }
                onClick={ onClickChannel }
                showFormat={ !!channel && channel.format }
                showId={ channel ? channel.id : null }
                showSlug={ channel.slug }
                showTitle={ !!channel && channel.title }
                useOverlay={ !hideSlateOverlayInfo }
                useOverlayContent
              />
            ))
          }
          { /* Fixes issue with padding with snap scrolling on safari, by adding a spacer element at the end */ }
          { browserIsDesktopSafari && safariScrollRequiresSpacer && !isFirstRender && <div style={ { width: `${ paddingLeft - gridGap }px` } } /> }
        </ul>
      </div>
    </div>
  );
};

ShowPreviewRow.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  channelPreviews: PropTypes.arrayOf(PropTypes.object),
  recommendationId: PropTypes.string,
  recommendationType: PropTypes.string,
  recommendationSlug: PropTypes.string,
  rowRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  isLoading: PropTypes.bool,
  isLoadingShows: PropTypes.bool,
  error: PropTypes.bool,
  disableHeader: PropTypes.bool,
  disableNativeScroll: PropTypes.bool,
  isExpandable: PropTypes.bool,
  hideDescription: PropTypes.bool,
  disable: PropTypes.bool,
  className: PropTypes.string,
  disableImpressionEvent: PropTypes.bool,
  useTouchControls: PropTypes.bool,
  isInView: PropTypes.bool,
  onClickChannel: PropTypes.func,
  onImpressionSuccess: PropTypes.func,
  hideSlateOverlayInfo: PropTypes.bool
};

ShowPreviewRow.defaultProps = {
  id: '',
  title: '',
  description: '',
  channelPreviews: [],
  recommendationId: '',
  recommendationType: '',
  recommendationSlug: '',
  rowRef: undefined,
  isLoading: false,
  isLoadingShows: false,
  error: false,
  disableHeader: false,
  disableNativeScroll: false,
  isExpandable: false,
  hideDescription: false,
  disable: false,
  className: '',
  disableImpressionEvent: false,
  useTouchControls: false,
  isInView: false,
  onClickChannel: undefined,
  onImpressionSuccess: undefined,
  hideSlateOverlayInfo: false
};

export default ShowPreviewRow;