import PropTypes from 'prop-types';
import Text from '..';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';

const TextLoadingBlock = ({
  size,
  className,
  lineCount
}) => {
  return (
    <div className={ classes(className, styles.loadingTextBlock) }>
      {
        [...Array(lineCount)].map((_, i) => (
          <Text
            className={ styles.loadingTextBlockLine }
            key={ i }
            loading
            size={ size }
          />
        ))
      }
    </div>
  );
};

TextLoadingBlock.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  lineCount: PropTypes.number
};

TextLoadingBlock.defaultProps = {
  size: undefined,
  className: '',
  lineCount: 2
};

export default TextLoadingBlock;