import {
  IC_EVT_VIEWED_PAGE_DISCOVER,
  IC_EVT_VIEWED_PAGE_GENRE,
  IC_EVT_VIEWED_PAGE_LIBRARY,
  IC_EVT_VIEWED_PAGE_SHOW
} from '@/config/events-intercom/events';
import {
  IC_META_GENRE_ID,
  IC_META_GENRE_PARENT_ID,
  IC_META_GENRE_PARENT_TITLE,
  IC_META_GENRE_TITLE,
  IC_META_IS_NEW,
  IC_META_SHOW_ID,
  IC_META_SHOW_TITLE
} from '@/config/events-intercom/metadata';

export const ICpageViewDiscover = () => ({ eventName: IC_EVT_VIEWED_PAGE_DISCOVER });

export const ICpageViewLibrary = () => ({ eventName: IC_EVT_VIEWED_PAGE_LIBRARY });

export const ICpageViewShow = ({ showId, showTitle }) => ({
  eventName: IC_EVT_VIEWED_PAGE_SHOW,
  metadata: {
    [IC_META_SHOW_ID]: showId,
    [IC_META_SHOW_TITLE]: showTitle
  }
});

export const ICpageViewGenre = ({
  genreId,
  genreTitle,
  parentGenreId,
  parentGenreTitle,
  isNew
}) => ({
  eventName: IC_EVT_VIEWED_PAGE_GENRE,
  metadata: {
    [IC_META_GENRE_ID]: genreId,
    [IC_META_GENRE_TITLE]: genreTitle,
    [IC_META_GENRE_PARENT_ID]: parentGenreId || undefined,
    [IC_META_GENRE_PARENT_TITLE]: parentGenreTitle || undefined,
    [IC_META_IS_NEW]: isNew || undefined
  }
});