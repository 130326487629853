import PropTypes from 'prop-types';
import { useMemo } from 'react';
import ButtonCTA from '../CTA';
import Span from '../../Span';
import PlayRoundedIcon from '@/components/component-library/Icon/types/PlayRounded';
import PauseRoundedIcon from '@/components/component-library/Icon/types/PauseRounded';
import { classes } from '@/helpers/styling';

const ButtonPlayPauseCTA = ({
  paused,
  className,
  style,
  onClick,
  loading,
  disabled,
  seasonNumber,
  episodeNumber,
  isCurrentlyPlayingShow,
  disableEpisodeText,
  ...props
}) => {
  let buttonText = isCurrentlyPlayingShow ? 'Resume' : 'Play';

  const showEpisodeDetails = seasonNumber && episodeNumber;
  const seasonEpisodeTOptions = useMemo(() => ({
    episodeNumber,
    seasonNumber
  }), [episodeNumber, seasonNumber]);

  if (showEpisodeDetails) {
    if (paused || !isCurrentlyPlayingShow) {
      buttonText = disableEpisodeText
        ? buttonText
        : `Play Season ${seasonNumber} Episode ${episodeNumber}`;
    } else if (!paused) {
      buttonText = 'Pause';
    }
  } else if (!paused && isCurrentlyPlayingShow) {
    buttonText = 'Pause';
  }

  return (
    <ButtonCTA
      className={classes(className, 'button-cta-play-pause')}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      style={style}
      {...props}
    >
      <Span className='button-cta-play-pause-icon'>
        {paused || !isCurrentlyPlayingShow ? <PlayRoundedIcon /> : <PauseRoundedIcon />}
      </Span>
      {buttonText}
    </ButtonCTA>
  );
};

ButtonPlayPauseCTA.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  episodeNumber: PropTypes.number,
  isCurrentlyPlayingShow: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  paused: PropTypes.bool,
  seasonNumber: PropTypes.number,
  style: PropTypes.object,
  disableEpisodeText: PropTypes.bool
};

ButtonPlayPauseCTA.defaultProps = {
  className: '',
  disabled: false,
  episodeNumber: undefined,
  isCurrentlyPlayingShow: false,
  loading: false,
  onClick: undefined,
  paused: true,
  seasonNumber: undefined,
  style: {},
  disableEpisodeText: false
};

export default ButtonPlayPauseCTA;
