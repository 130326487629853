import { useEffect, useState } from 'react';
import { getBrowserWidthObject } from '@/helpers/assets';
import { isBrowser } from '@/helpers/browser';
import useWindowSize from '@/hooks/browser/useWindowSize';

const useBrowserAssetWidthRange = () => {
  const [assetSizeForScreenWidth, setAssetSizeForScreenWidth] = useState();
  const [assetWidthRange, setassetWidthRange] = useState({
    min: 0,
    max: 0
  });

  const { width } = useWindowSize({ debounceTime: 250 });

  const inBrowserMode = isBrowser();

  useEffect(() => {
    if (inBrowserMode) {
      const { key: newassetSizeForScreenWidth, range } = getBrowserWidthObject(width);

      if (newassetSizeForScreenWidth !== assetSizeForScreenWidth) {
        setAssetSizeForScreenWidth(newassetSizeForScreenWidth);
        setassetWidthRange(range);
      }
    }
  }, [inBrowserMode, width, assetSizeForScreenWidth]);

  return {
    assetSizeForScreenWidth,
    assetWidthRange
  };
};

export default useBrowserAssetWidthRange;