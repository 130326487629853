import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { classes } from '@/helpers/styling';

const HeroShiftedPageContent = ({ children, className }) => {
  return (
    <div className={ classes(styles.heroShiftedPageContent, styles.showContentWrapper, className) }>
      { children }
    </div>
  );
};

HeroShiftedPageContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};

HeroShiftedPageContent.defaultProps = { className: '' };

export default HeroShiftedPageContent;