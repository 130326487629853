import { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { size } from '@/helpers/lodash';
import Badge from '@/components/component-library/Badge';
import { classes } from '@/helpers/styling';

const BadgeWithWrapper = ({ ...props }) => {
  return (
    <li className={ styles.badgeWrapper }>
      <Badge { ...props } />
    </li>
  );
};

const BadgeList = ({
  items,
  onClickBadge,
  enableLinks,
  loading,
  loadingCount,
  isVerticallyCentered,
  className,
  ...props
}) => {
  const content = loading
    ? [...Array(loadingCount)].map((_, i) => <BadgeWithWrapper key={ `loading-${ i }` } loading { ...props } />)
    : (
      !!size(items) && items.map((item) => (
        <BadgeWithWrapper
          href={ enableLinks ? item.href : undefined }
          key={ item.id }
          onClick={ onClickBadge ? () => onClickBadge(item) : undefined }
          title={ item.title }
          { ...props }
        />
      ))
    );

  return (
    <ul className={ classes(className, styles.badgeList, { [styles.isVerticalltyCentered]: isVerticallyCentered }) }>
      { content }
    </ul>
  );
};

BadgeList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onClickBadge: PropTypes.func,
  enableLinks: PropTypes.bool,
  loading: PropTypes.bool,
  loadingCount: PropTypes.number,
  isVerticallyCentered: PropTypes.bool,
  className: PropTypes.string
};

BadgeList.defaultProps = {
  items: [],
  onClickBadge: undefined,
  enableLinks: true,
  loading: false,
  loadingCount: 2,
  isVerticallyCentered: false,
  className: ''
};

export default memo(BadgeList);