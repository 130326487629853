// SHOWS
export const IC_META_SHOW_ID = 'show_id';
export const IC_META_SHOW_TITLE = 'show_title';

// GENRES
export const IC_META_GENRE_ID = 'genre_id';
export const IC_META_GENRE_TITLE = 'genre_title';
export const IC_META_GENRE_PARENT_ID = 'parent_genre_id';
export const IC_META_GENRE_PARENT_TITLE = 'parent_genre_title';
export const IC_META_GENRES = 'genres';
export const IC_META_IS_NEW = 'is_new';

// EPISODE
export const IC_META_EPISODE_ID = 'episode_id';
export const IC_META_EPISODE_NUMBER = 'episode_number';
export const IC_META_EPISODE_SEASON_NUMBER = 'episode_season_number';

// SEARCH
export const IC_META_SEARCH_TERM = 'search_term';
export const IC_META_SEARCH_FILTER = 'search_filter';

// AUDIO
export const IC_META_PERCENTAGE_PROGRESS = 'percentage_progress';
export const IC_META_TOTAL_LISTENING_TIME = 'total_listening_time';

// COMMON
export const IC_META_RESULTS_COUNT = 'result_count';